/**
 * [Create resizeEnd Event]
 */
$(window).resize(function() {
    if(this.resizeTO) clearTimeout(this.resizeTO);
    this.resizeTO = setTimeout(function() {
        $(this).trigger('resizeEnd');
    }, 100);
});
/**
 * [Create scrollEnd Event]
 */
$(window).scroll(function() {
    if(this.scrollTO) clearTimeout(this.scrollTO);
    this.scrollTO = setTimeout(function() {
        $(this).trigger('scrollEnd');
    }, 100);
});

$(window).on('resizeEnd', function() {
    utilities.init();
});

//$(window).on('scroll', function () {
//   isiJS.peekabooOn();
//});


var drFinder = (function () {
    return {
        trackNpiVerified: function (verifiedFlag) {
            evoAnalytics.trackEvent("Edit doctor profile", "Verify NPI Number", verifiedFlag ? "Verified" : "Not Verified");
        },
        trackUpdateProfileClick: function (sender) {
            if ($("#finderEdit").css("display") != "none") {
                var $sender = $(sender);
                if ($sender.hasClass("finder-update-link-bottom")) {
                    evoAnalytics.trackEvent(['Edit doctor profile', 'Click Edit Profile', 'Bottom Link']);
                } else {
                    evoAnalytics.trackEvent(['Edit doctor profile', 'Click Edit Profile', 'Top Link']);
                }
            }
        }
    }
})();

var display = (function () {

    var staticHeader = (function () {
        var lastScroll = -1;
        var currentScroll = 0;
        var $window = $(window);
        var $document = $(document);
        var $header = $("header");
        var headerHeight = $header.outerHeight();
        var $nav = $header.find("nav.nav-main");
        var navHeight = $nav.height();
        var animatingUp = false;
        var animatingDown = false;
        var isMobile = false;
        var showMenu = false;
        var enabled = true;

        function initDesktop() {
            $header.css({
                "top": "0px"
            });
        }
        function initMobile() {
            $header.css({
                "top": "0px"
            });
        }

        function init() {
            if (evoke.core.windowWidth() < 1000) {
                if (!isMobile) {
                    isMobile = true;
                    initMobile();
                }
            } else {
                if (isMobile) {
                    isMobile = false;
                    initDesktop();
                }
            }
            var staticHeaderDisabled = httpUtility.cookie.get("staticHeaderDisabled");
            if (staticHeaderDisabled === "true") {
                disable();
            }
        }

        function resize() {
            if (evoke.core.windowWidth() < 1000) {
                if (!isMobile) {
                    isMobile = true;
                    initMobile();
                }
            } else {
                if (isMobile) {
                    isMobile = false;
                    initDesktop();
                }
            }
        }

        function scroll() {
            if (evoke.core.windowWidth() < 1000) {
                if (!isMobile) {
                    initMobile();
                }
                isMobile = true;

                if (enabled) {
                    currentScroll = $window.scrollTop();

                    var headerPosTop = $header.position().top;
                    var headerNavHeight = headerHeight;
                    if ($nav.css("display") != "none") {
                        headerNavHeight += navHeight;
                    }
                    if (currentScroll < lastScroll) { //scrolling up, show header
                        if (!animatingUp && headerPosTop != 0) {
                            animatingDown = false;
                            animatingUp = true;
                            $header.stop().animate({ "top": "0px" }, { duration: 300, complete: function () { animatingUp = false; } });
                            if (showMenu) {
                                showMenu = false;
                                menu.mobile.show();
                            }
                        }
                    } else if (currentScroll > headerHeight) { //scrolling down, hide header
                        if (lastScroll < 0) {
                            $header.css({ "top": "-" + headerHeight.toString() + "px" });
                        } else if (!animatingDown && headerPosTop != -headerHeight) {
                            animatingUp = false;
                            animatingDown = true;
                            lastHide = currentScroll;
                            if (menu.mobile.isVisible()) {
                                showMenu = true;
                                menu.mobile.hide();
                            }
                            $header.stop().animate({ "top": "-" + headerHeight.toString() + "px" }, { duration: 300, complete: function () { animatingDown = false; } });
                        }
                    }

                    lastScroll = currentScroll;
                }

            } else {
                lastScroll = 0;
                if (isMobile) {
                    initDesktop();
                }
                isMobile = false;
            }
        }

        function show() {
            if (utilities.mView()) {
                $header.css({
                    "position": "fixed",
                    "top": "0px"
                });
            }
        }
        function hide() {
            if (utilities.mView()) {
                $header.css({
                    "position": "fixed",
                    "top": $header.outerHeight().toString() + "px"
                });
            }
        }

        function disable() {
            enabled = false;
            $header.css({ "position": "absolute", "top": "0px" });
        }
        function enable() {
            enabled = true;
            $header.css({ "position": "fixed", "top": "0px" });
            scroll();
        }

        $document.ready(init);
        $window.on("resize", resize);
        $window.on("scroll", scroll);

        return {
            scroll: scroll,
            show: show,
            hide: hide,
            disable: disable,
            enable: enable
        };
    })();

    function refreshRightISI() {
        rightISI.scroll();
        //$(".sticky-isi").each(function () {
        //    this.stickyBox.refresh();
        //});
    }

    return {
        refreshRightISI: refreshRightISI,
        staticHeader: staticHeader
    };
})();

/**
* [Basic functions]
*/
var utilities  = (function() {

  var breakPoint  = 1000;

  function winWidth() {
    return window.innerWidth;
  }

  function isMobile() {
    if (winWidth() < breakPoint) {
      return true;
    }
  }

  function findSpecialist(zipCode) {
    var zipQueryString = "";

    if (zipCode.length === 5) {
      zipQueryString = '?loc=' + zipCode;
    }

    document.location = "/getting-started/find-a-specialist" + zipQueryString;
  }

  return {

    findSpecialist: findSpecialist,
    winW: winWidth,
    bp: breakPoint,
    mView: isMobile,
    init: function () {

      /**
      * [Initialize scripts based on screen width]
      */
      if (isMobile()) {
        utilities.mobileInit();
      } else {
        utilities.desktopInit();
      }

    },
    mobileInit: function() {
      menu.mobileOn();
      //isiJS.peekabooOn();
      menu.desktopOff();
    },
    desktopInit: function() {
      menu.mobileOff();
      //isiJS.peekabooOff();
      menu.desktopOn();
    },
    scrollTo: function(selector) {

      var scrollOffset = $('.container').css('padding-top').replace(/[^-\d\.]/g, '');

      $('html,body').animate({
        scrollTop: $(selector).offset().top - scrollOffset
      }, 500);
    }

  };

})();


/**
 * [Functions for Main Navigation]
 */
var menu = (function() {

  var menuButton  = $('.hamburger'),
      nav         = $('.nav-main'),
      subnav      = $('.sub-nav'),
      subTrigger  = $('.nav-main > ul > li').not('.find-a-specialist'),
      menuOn;
  function isVisible() {
      return (nav.css("display") != "none");
  }
  function hide() {
      if (isVisible()) {
          nav.slideUp("fast", function () {
              menuButton.removeClass("open");
          });
      }
  }
  function show() {
      if (!isVisible()) {
          nav.slideDown("fast", function () {
              menuButton.addClass("open");
          });
      }
  }
  var mobile = {
      isVisible: isVisible,
      show: show,
      hide: hide
  }
  function showMenu() {
      var _this = $(this);

      nav.slideToggle('fast', function () {
          _this.toggleClass('open');
      });
  }

  return {

      mobile: mobile,
    mobileOn: function() {

      if (!menuOn) {

        menuButton.on('click', showMenu);

        menuOn = true;

      }

    },
    mobileOff: function() {
        menuButton.removeAttr('style').off('click', showMenu);
      nav.removeAttr('style');
      menuOn = false;
    },
    desktopOn: function() {

      $('.marker').each(function() {
        var _this = $(this),
            parentLink = _this.parents('li'),
            markerPos = parentLink.position().left + (parentLink.width() / 2) - 4;

            _this.css('left', markerPos);

      });

      subTrigger.on('mouseenter', function() {
        subnav.hide();
        $(this).children('.sub-nav').show(0);
      });

      nav.on('mouseleave', function() {
        subnav.removeAttr('style');
      });

    },
    desktopOff: function() {
      subTrigger.off('mouseenter mouseleave');
      subnav.removeAttr('style');
    }

  };

})();

var tooltip = (function() {

  var tip = '<div class="tooltip-popup"></div>';

  return {

    init: function() {
      $('*[data-tooltip]').each(function() {
        var _this = $(this);
        tooltip.createPopup(_this);
      });
      $(window).on('resizeEnd', function() {
        tooltip.resize();
      });
    },
    resize: function() {
      $('*[data-tooltip]').each(function() {
        var _this = $(this);
        tooltip.placement(_this);
      });
    },
    createPopup: function(t) {
      var term        = t.text(),
          defHeader   = '<strong>' + term + '</strong>',
          definition  = defHeader + " " + t.data('tooltip'),
          tip         = $('<div class="tooltip-popup">' + definition + '</div>');

      t.append(tip);
      tooltip.placement(t);
    },
    placement: function(t) {
      var boundry     = t.parents('.left-col'),
          trigLeft    = t.offset().left,
          trigRight   = t.offset().left + t.outerWidth(),
          leftEdge    = boundry.offset().left,
          rightEdge   = boundry.offset().left + boundry.outerWidth(),
          popup       = t.children('.tooltip-popup'),
          tPos        = (t.outerWidth()/2) - (popup.outerWidth() / 2),
          popupLeft   = popup.offset().left,
          popupRight  = popup.offset().left + popup.outerWidth();

      popup.removeAttr('style').css('left', tPos);

      if (popupLeft <= leftEdge) {
        popup.css('left', (trigLeft - leftEdge) * -1);
      } else if (popupRight > rightEdge) {
        popup.css({
          left: 'inherit',
          right: (rightEdge - trigRight) * -1
        });
      }

    }

  };

})();

if ($("#isi-fixed").length > 0) {
    var mobileIsiDisabled = httpUtility.cookie.get("mobileIsiDisabled");
    if (mobileIsiDisabled !== "true") {
        $("#isi-fixed").staticBox({
            activeMaxWidth: 999,
            hideWhenViewable: "#isi"
        });
    }
}

$("a[href='#isi']").each(function () {
    $(this).click(function (e) {
        e.preventDefault();
        var box = $("#isi-fixed")[0].staticBox;
        box.hide();
        box.disable();
        evoke.jquery.scrollTo("#isi", {
            extraY: -10,
            callback: function () {
                box.enable();
            }
        });
    });
});

/**
 * [Functions for ISI persistance]

var isiJS = (function () {

    var ISI = $('#isi'),
      fixedISI = $('#isi-fixed'),
      inputs = $('input[type="text"], input[type="email"], select'),
      isiHeight = 106;

    return {

        fieldFocus: inputs,
        peekabooOn: function () {
                   if (ISI.length > 0) {
                     if (utilities.mView()) {

                       if ($(window).scrollTop() > ISI.offset().top - $(window).height() + isiHeight) {
                         fixedISI.fadeOut();
                       } else {
                         if (!inputs.is(':focus')) {
                           fixedISI.fadeIn();
                         }
                       }

                     }
                 }
        },
        peekabooOff: function () {

                 fixedISI.removeAttr('style');

        }

    };

})();
 */

/**
 * [Functions for FAQ]
 */
var FAQ = (function () {

    var allTrigger = $('.faq .expandAll'),
      thisTrigger = $('dt');

    return {

        init: function () {

            allTrigger.on('click', function () {
                var t = $(this);
                FAQ.sectionHandler(t);
            });

            thisTrigger.on('click', function () {
                var t = $(this);
                FAQ.questionHandler(t);
            });

        },
        sectionHandler: function (t) {
          var section = t.parents('li'),
          answers = section.find('dd'),
          question = section.find('dt');

            if (!t.hasClass('expanded')) {
                evoAnalytics.trackEvent(evoAnalytics.parseAnalyticsData(t.data("ga-expand")));
                evoke.gtm.trackEvent(t.data("gtm-expand"));
                answers.slideDown('fast', function () {
                    t.addClass('expanded').text('COLLAPSE ALL [-]');
                    question.addClass('open');
                });

            } else {
                evoAnalytics.trackEvent(evoAnalytics.parseAnalyticsData(t.data("ga-collapse")));
                evoke.gtm.trackEvent(t.data("gtm-collapse"));
                answers.slideUp('fast', function () {
                    t.removeClass('expanded').text('EXPAND ALL [+]');
                    question.removeClass('open');
                    answers.removeAttr('style');
                });
            }

        },
        questionHandler: function (t) {
          var answer = t.siblings('dd'),
          section = t.parents('li').find('button'),
          siblings = t.parents('dl').siblings().find('dt');

            if (!t.hasClass('open')) {
                evoAnalytics.trackEvent(evoAnalytics.parseAnalyticsData(t.data("ga-expand")));
                evoke.gtm.trackEvent({ data: t.data("gtm-expand"), selector: t });
                answer.slideDown('fast', function () {
                    section.addClass('expanded').text('COLLAPSE ALL [-]');
                    t.addClass('open');
                });

            } else {
                evoAnalytics.trackEvent(evoAnalytics.parseAnalyticsData(t.data("ga-collapse")));
                evoke.gtm.trackEvent({ data: t.data("gtm-collapse"), selector: t });
                answer.slideUp('fast', function () {
                    t.removeClass('open');
                    answer.removeAttr('style');

                    if (!siblings.hasClass('open')) {
                        section.removeClass('expanded').text('EXPAND ALL [+]');
                    }

                });

            }

        }

    };

})();


/**
* [Checkbox/Radio Button Styler]
*/
(function () {
    //taken from evoke.jquery.evokeUI
    function toggleChecked($objs, checkedClass) {
        $objs.each(function () {
            var label = $(this);
            if (label.find("input:checked").length > 0) {
                if (!label.hasClass(checkedClass)) {
                    label.addClass(checkedClass);
                }
            } else {
                label.removeClass(checkedClass);
            }
        });
    }
    function toggleRadioButton($obj, checkedClass) {
        $("input[name='" + $obj.attr("name") + "']").each(function () {
            var label = $(this).parents("label").first();
            if (this.checked) {
                if (!label.hasClass(checkedClass)) {
                    label.addClass(checkedClass);
                }
            } else {
                label.removeClass(checkedClass);
            }
        });
    }
    function initCheckboxes($objs, checkedClass) {
        $objs.each(function () {
            var label = $(this);
            label.on("click", function () {
                toggleChecked($(this), checkedClass);
            });
            label.find("input[type='checkbox']").each(function () {
                $(this).on("change", function () {
                    toggleChecked($(this).parents("label:first"), checkedClass);
                });
            });
            toggleChecked($(this), checkedClass);
        });
    }
    function initRadioButtons($objs, checkedClass) {
        var groups = [];
        $objs.each(function () {
            $(this).find("input[type='radio']").each(function () {
                $(this).on("change", function () {
                    toggleRadioButton($(this), checkedClass);
                });
                toggleRadioButton($(this), checkedClass);
            });
        });
    }
    $(document).ready(function() {
        initRadioButtons($(".label_check"), "c_on");
        initCheckboxes($(".label_checkbox"), "c_on");
    });
})();

/*
var customUI = {
    update: function () {
        var _this = $(this);

        if ($('#content').hasClass('ready')) {
            _this.parents('ul').find(".label_check, .label_checkbox").removeClass("c_on");
        } else{
            _this.parent().find(".label_check, .label_checkbox").removeClass("c_on");
        }

        if (_this.find("input:checked").length > 0) {
            _this.addClass("c_on");
        }
    },
    init: function () {
        $(".label_check, .label_checkbox").each(function () {
            var _this = $(this);
            _this.change(customUI.update);
            if (_this.find("input:checked").length > 0) {
                _this.addClass("c_on");
            }
        });
    }
};
$(document).ready(customUI.init);
*/

/**
* [Smooth Anchor Link Scroller]
*/
$('body').on('click', 'a[href*=#]:not([href=#],[href=#isi])', function () {
  if (location.pathname.replace(/^\//,'') == this.pathname.replace(/^\//,'') && location.hostname == this.hostname) {
    var target = $(this.hash);
    target = target.length ? target : $('[name=' + this.hash.slice(1) +']');
    if (target.length) {
      utilities.scrollTo(target);
      return false;
    }
  }
});

$(document).ready(function () {

  // remove phone numbers from desktop/tablet
  var $html = $("html");
  if ($html.hasClass("d_desktop") || $html.hasClass("d_tablet")) {
    $("a[href^='tel:']").each(function () {
      this.href = "javascript:void(0);";
    });
  }

  // numbers ony for ZIP code field
  $('.find-zip-field').numeric();
  // specialist finder - hit return
  $('.find-zip-field').keyup(function (e) {
    var $this = $(this);
    var zipCode = $this.val();

    if (zipCode.length === 5) {
        if (e.which == 13) {
            //evoAnalytics.trackEvent($this.parent().find('.find-zip-submit').data('analytics-event'));
            //evoke.gtm.trackEvent($(this).next('.find-zip-submit').data('evo-gtm'));

            $this.blur();
            $this.parent().find('.find-zip-submit').click();
        } else if (e.which >= 48 && e.which <= 57) {
            evoAnalytics.trackEvent($(this).data('analytics-event'));
            //evoke.gtm.trackEvent($(this).data('evo-gtm'));
        }
    }
  });

  // specialist finder - on click
  $('body').on('click', '.find-zip-submit', function(e) {

    e.preventDefault();

    var zipCode = $(this).prev('.find-zip-field').val();

    utilities.findSpecialist(zipCode);

  });

  // specialist finder - on click
  // commented out because tracking for this is handled elsewhere
  // $('.find-zip-field').on('focus', function(e) {

  //   evoke.gtm.trackEvent($(this).data('evo-gtm'), {action: "Form Clicked"});

  // });

  // specialist finder top nav hover
  $('.nav-main .find-a-specialist').hover(
    function() {
      $(this).addClass('show-zip');
      evoAnalytics.trackEvent('Global Header','Hover','Click to find a specialist');
    }, function() {
      if (!$(this).find('.find-zip-input input').is(':focus')) {
        $(this).removeClass('show-zip');
      }
    }
  );

  $('.find-a-specialist a').on('click', function(e) {
    e.preventDefault();

    var $zipLink = $('.find-zip-field');

    if ($zipLink.val().length === 5) {
      evoAnalytics.trackEvent($(this).data('analytics-event'));
      utilities.findSpecialist($zipLink.val());
    }

  });

  // read more/less
  $('.read-more-trigger').on('click', function(e) {

    e.preventDefault();

    if (!$(this).hasClass('open')) {
      $(this).addClass('open').text('Read Less').parent().next('.read-more-target').slideDown(function() {
        if ($('#about-three .about-genotype').length > 0) {
          $.scrollIntoView('#about-three .about-genotype');
        }
        if ($('#treatment-three .treatment-consider').length > 0) {
          $.scrollIntoView('#treatment-three .treatment-consider');
        }
      });
    }
    else {
      $(this).removeClass('open').text('Read More').parent().next('.read-more-target').slideUp();
    }
  });

  $(".modal-external").find("a").click(function (e) {
      var $target = $(e.target);
      if (!($target.hasClass("ok"))) {
          e.preventDefault();
      }
      $.modalDialog.hide();
  });
  $("a.external").each(function () {
      function modalRedraw() {
          $.modalDialog.redraw();
      }
      $(this).click(function (e) {
          var $this = $(this);
          e.preventDefault();
          var $modal = $(".modal-external");

          var $ok = $modal.find(".ok");
          $ok.attr("href", $this.attr("href"));
          $ok.attr("data-evo-gtm", $this.data("modal-ok"));
          evoke.gtm.rebind($ok);

          var $cancel = $modal.find(".cancel");
          $cancel.attr("data-evo-gtm", $this.data("modal-cancel"));
          evoke.gtm.rebind($cancel);

          var dataClose = $this.data("modal-close");
          var $close = $modal.find(".close");
          $close.attr("data-evo-gtm", dataClose);
          evoke.gtm.rebind($close);

          $.modalDialog.show($modal, null, {
              callback: function () {
                  evoke.$window.on("resize", modalRedraw);
              },
              onHide: function () {
                  evoke.$window.off("resize", modalRedraw);
              },
              onGrayClick: function () {
                  evoke.gtm.trackEvent(dataClose);
              }
          });
      });
  });

});

//isiJS.fieldFocus.on('focus', function() {
// isiJS.peekabooOff();
//});

//isiJS.fieldFocus.on('blur', function() {
// isiJS.peekabooOn();
//});

utilities.init();
FAQ.init();
tooltip.init();


/**
* Returns the window width, including the scrollbar width, for media query matching. $(window).width() does not include the scrollbar width.
*/
function windowWidth() {
    return window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
}

function staticHeaderHeight() {
    var height = 0;
    if (windowWidth() >= 1000) {
        var $header = $("header");
        var $subnav = $header.find("nav > ul > li.active > .sub-nav");
        height = $header.height() + ($subnav.length == 1 ? $subnav.height() : 0);
    }
    return height;
}



function getIndexFromClass(selector, className) {
    var exp = className + "\\d";
    var regex = new RegExp(exp, "i");
    var replace = new RegExp(className, "i");
    var $obj = $(selector);
    var index = "";
    if ($obj.length > 0) {
        try {
            index = parseInt(regex.exec($obj.attr("class"))[0].replace(replace, ""), 10);
        } catch (e) { }
    }
    return index;
}


(function () {
    var $body = $(document.body);
    if ($body.hasClass("support-path")) {
        evoAnalytics.category = "HARVONI Support Path Video";
    } else if ($body.hasClass("next-steps")) {
        evoAnalytics.category = "Your Next Steps Patient Statements";
    }
})();

// var contentVideoPlayer = (function () {
//     var videoPlayerID = "contentVideoPlayer";
//     var vimeoPlayerID = "contentVimeoPlayer";
//     var videoID = "";
//     var embedded = false;
//     var currentSlide = 0;
//     var slides = [];
//     var dots = [];
//     var queuePoints = [];
//     var queueLabels = [];
//     var autoSlides = true;
//     var autoSlidesTimeout = 0;
//     var autoSlideShow = false;
//     var isSeeking = false;
//     var isSliding = false;
//     var wasPaused = false;
//     var initialized = false;
//     var shouldEmbed = true;
//     var autoplay = httpUtility.queryString("autoplay") == "true";

//     var events = {
//         onFirstPlay: function () { },
//         onPlay: function () { },
//         onPlayProgress: function (seconds) { },
//         onPause: function () { },
//         onFullscreen: function () { },
//         onSeek: function () { },
//         onFinish: function () { },
//         onClose: function () { },
//         onSlideNavClick: function () { },
//         onSlideNavClickLeft: function () { },
//         onSlideNavClickRight: function () { },
//         onSlideNavVideo: function () { },
//         onSlideNavVideoAuto: function () { }
//     };

//     var tracking = {
//         firstPlay: { action: "Video Event", label: "##videoName## Play" },
//         play: { action: "Video Event", label: "##videoName## Play After Stop/Pause" },
//         pause: { action: "Video Event", label: "##videoName## Stop/Pause" },
//         fullscreen: { action: "Video Event", label: "##videoName## Video Expand" },
//         seek: { action: "Video Event", label: "##videoName## Seek" },
//         finish: { action: "Video Event", label: "##videoName## Finish" },
//         close: { action: "Close", label: "Exit Video" },
//         slideNavClick: { action: "Click", label: "click to ##label##" },
//         slideNavClickLeft: { action: "Click", label: "left to ##label##" },
//         slideNavClickRight: { action: "Click", label: "right to ##label##" },
//         slideNavVideo: { action: "Video Event", label: "##label##" },
//         slideNavVideoAuto: { action: "Auto Event", label: "auto back to ##label##" }
//     }
//     function setTrackingDataVideoName(videoName) {
//         for (var data in tracking) {
//             if (typeof (tracking[data].label) != "undefined" && tracking[data].label.indexOf("##videoName##") != -1) {
//                 tracking[data].label = tracking[data].label.replace("##videoName##", videoName).trim();
//             }
//         }
//     }
//     function getTrackingData(eventData, labelData) {
//         var data = {
//             action: eventData.action,
//             label: eventData.label
//         };
//         if (labelData != undefined) {
//             if (typeof (labelData) == "string") {
//                 data.label = labelData;
//             } else {
//                 for (var token in labelData) {
//                     data.label = data.label.replace("##" + token + "##", labelData[token]);
//                 }
//             }
//         }
//         return data;
//     }

//     var $root = null;
//     var $video = null;
//     var $iframe = null;
//     var $slides = null;
//     function setAutoSlides(flag) {
//         window.clearTimeout(autoSlidesTimeout);
//         autoSlides = flag;
//         if (!autoSlides) {
//             autoSlidesTimeout = window.setTimeout(function () {
//                 autoSlideShow = true;
//                 autoSlides = true;
//             }, 10000);
//         }
//     }
//     function showSlide(num, from) {
//         if (!isSeeking && !isSliding) {
//             if (num != currentSlide && num >= 0 && num < slides.length) {
//                 isSliding = true;
//                 var width = $slides.width();
//                 if (num > currentSlide) {
//                     slides[num].css({
//                         left: width,
//                         display: "block"
//                     });
//                     slides[currentSlide].animate({
//                         left: -(width)
//                     }, { complete: function () { $(this).hide(); } });
//                     slides[num].animate({
//                         left: 0
//                     }, {
//                         complete: function () {
//                             dots[currentSlide].removeClass("active");
//                             dots[num].addClass("active");
//                             currentSlide = num;
//                             isSliding = false;
//                         }
//                     });
//                 } else {
//                     slides[num].css({
//                         left: -(width),
//                         display: "block"
//                     });
//                     slides[currentSlide].animate({
//                         left: width
//                     }, { complete: function () { $(this).hide(); } });
//                     slides[num].animate({
//                         left: 0
//                     }, {
//                         complete: function () {
//                             dots[currentSlide].removeClass("active");
//                             dots[num].addClass("active");
//                             currentSlide = num;
//                             isSliding = false;
//                         }
//                     });
//                 }

//                 var eventFrom = "video";
//                 if (from != undefined && from != null) {
//                     eventFrom = from;
//                 }

//                 switch (eventFrom.toLowerCase()) {
//                     case "click":
//                         evoAnalytics.trackEvent(getTrackingData(tracking.slideNavClick, { label: queueLabels[num] }));
//                         events.onSlideNavClick();
//                         break;
//                     case "clickleft":
//                         evoAnalytics.trackEvent(getTrackingData(tracking.slideNavClickLeft, { label: queueLabels[num] }));
//                         events.onSlideNavClickLeft();
//                         break;
//                     case "clickright":
//                         evoAnalytics.trackEvent(getTrackingData(tracking.slideNavClickRight, { label: queueLabels[num] }));
//                         events.onSlideNavClickRight();
//                         break;
//                     case "auto":
//                         autoSlideShow = false;
//                         evoAnalytics.trackEvent(getTrackingData(tracking.slideNavVideoAuto, { label: queueLabels[num] }));
//                         events.onSlideNavVideoAuto();
//                         break;
//                     case "video":
//                     default:
//                         evoAnalytics.trackEvent(getTrackingData(tracking.slideNavVideo, { label: queueLabels[num] })); //["Video Event", queueLabels[num]]);
//                         events.onSlideNavVideo();
//                         break;
//                 }

//                 //slides[currentSlide].hide();
//                 //slides[num].show();
//             }
//         }
//     }
//     function embed(autoPlay) {
//         if (!embedded) {
//             evoke.vimeo.embed({
//                 target: "#" + videoPlayerID + " > .video",
//                 id: vimeoPlayerID,
//                 video: videoID,
//                 events: {
//                     play: onPlay,
//                     playProgress: onPlayProgress,
//                     pause: onPause,
//                     seek: onSeek,
//                     finish: onFinish,
//                     onFullscreenChange: function (flag) {
//                         if (flag === true) {
//                             evoAnalytics.trackEvent(tracking.fullscreen);
//                             events.onFullscreen();
//                         }
//                     }
//                 },
//                 autoPlay: autoPlay || false
//             });
//             embedded = true;
//         }
//     }
//     function play() {
//         if (!embedded) {
//             embed(true);
//         } else {
//             evoke.vimeo.play(vimeoPlayerID);
//         }
//     }
//     function pause() {
//         evoke.vimeo.pause(vimeoPlayerID);
//     }
//     function onPlay() {
//         if (wasPaused) {
//             evoAnalytics.trackEvent(tracking.play);
//             events.onPlay();
//         } else {
//             evoAnalytics.trackEvent(tracking.firstPlay);
//             events.onFirstPlay();
//         }
//     }
//     function onPlayProgress(data, id) {
//         isSeeking = false;
//         var index = -1;
//         if (autoSlides) {
//             var seconds = parseInt(data.seconds, 10);
//             for (var i = 0; i < queuePoints.length; i++) {
//                 if (i == queuePoints.length - 1) {
//                     index = i;
//                     break;
//                 } else {
//                     if (seconds < queuePoints[i + 1]) {
//                         index = i;
//                         break;
//                     }
//                 }
//             }
//         }
//         if (index > -1) {
//             var from = autoSlideShow ? "auto" : null;
//             showSlide(index, from);
//         }
//         events.onPlayProgress(data);
//     }
//     function onPause() {
//         evoAnalytics.trackEvent(tracking.pause);
//         events.onPause();
//         wasPaused = true;
//     }
//     function onSeek(data, id) {
//         if (!isSeeking) {
//             evoAnalytics.trackEvent(tracking.seek);
//             events.onSeek();
//         }
//         isSeeking = true;
//     }
//     function onFinish() {
//         evoAnalytics.trackEvent(tracking.finish);
//         events.onFinish();
//     }
//     function resize() {
//         var isModal = false;
//         switch ($root.css("position")) {
//             case "fixed":
//             case "absolute":
//                 isModal = true;
//                 break;
//             default:
//                 isModal = $root.css("display") == "none";
//                 break;
//         }
//         var width = 0;
//         if (isModal) {
//             var winWidth = windowWidth();
//             if (winWidth >= 1000) {
//                 width = 642;
//             } else if (winWidth < 767) {
//                 width = winWidth - 20;
//                 if (width > 480) {
//                     width = 480;
//                 }
//             } else {
//                 width = 600;
//             }
//         } else {
//             width = $video.width();
//         }
//         var height = Math.floor(width * 0.5625); //16x9
//         $video.css({ height: height });
//         $iframe.css({ height: height });
//         if (isModal) {
//             $root.css({ width: width });
//             $.modalDialog.refresh(true);
//         }
//     }

//     function documentReady() {
//         if (initialized) {
//             $root = $("#" + videoPlayerID);
//             if ($root.length == 1) {
//                 var $videoID = $root.find(".videoID");
//                 if ($videoID.length == 1) {
//                     videoID = $videoID.val();
//                 }
//                 var $videoName = $root.find(".videoName");
//                 if ($videoName.length == 1) {
//                     setTrackingDataVideoName($videoName.val());
//                 }
//                 $(window).resize(resize);
//                 $video = $root.find(".video:first");
//                 $iframe = $video.find("iframe:first");
//                 if ($root.css("display") != "none") {
//                     resize();
//                 }
//                 $slides = $root.find(".slides");
//                 $slides.find(".slide").each(function () {
//                     var $this = $(this);
//                     var index = getIndexFromClass(this, "slide") - 1;
//                     slides[index] = $this;
//                     queuePoints[index] = parseInt($this.data("queue-point"), 10);
//                     queueLabels[index] = $this.data("queue-label");
//                 });
//                 $root.find(".dot").each(function () {
//                     dots[getIndexFromClass(this, "dot") - 1] = $(this);
//                 });
//                 if (shouldEmbed) {
//                     embed(autoplay);
//                 }
//             }
//         } else {
//             window.setTimeout(documentReady, 100);
//         }
//     }
//     $(document).ready(function () {
//         documentReady();
//     });
//     return {
//         init: function (data) {
//             if (data != undefined) {
//                 if (typeof (data.playerID) != "undefined") {
//                     videoPlayerID = data.playerID;
//                 } if (typeof (data.vimeoPlayerID) != "undefined") {
//                     vimeoPlayerID = data.vimeoPlayerID;
//                 }
//                 if (typeof (data.videoID) != "undefined") {
//                     videoID = data.videoID;
//                 }
//                 if (typeof (data.videoName) != "undefined") {
//                     setTrackingDataVideoName(data.videoName);
//                 }
//                 if (typeof (data.tracking) != "undefined") {
//                     $.extend(true, tracking, data.tracking);
//                 }
//                 if (typeof (data.events) != "undefined") {
//                     $.extend(true, events, data.events);
//                 }
//                 if (typeof (data.embed) != "undefined") {
//                     shouldEmbed = data.embed;
//                 }
//                 if (typeof (data.autoplay) != "undefined") {
//                     autoplay = data.autoplay;
//                 }
//             }
//             initialized = true;
//         },
//         videoID: function (id) {
//             videoID = id;
//         },
//         showSlide: function (num) {
//             setAutoSlides(false);
//             showSlide(num - 1, "click");
//         },
//         nextSlide: function () {
//             setAutoSlides(false);
//             showSlide(currentSlide + 1, "clickright");
//         },
//         prevSlide: function () {
//             setAutoSlides(false);
//             showSlide(currentSlide - 1, "clickleft");
//         },
//         show: function () {
//             $.modalDialog.show("#" + videoPlayerID, document.forms[0], {
//                 callback: function () {
//                     resize();
//                     play();
//                 },
//                 onHide: function () {
//                     pause();
//                     evoAnalytics.trackEvent(tracking.close);
//                     events.onClose();
//                 }
//             });
//         },
//         play: function () {
//             play();
//         },
//         pause: function () {
//             pause();
//         }
//     };
// })();


// var spVideo = (function () {
//     var args = {
//         playerID: "spVideo",
//         vimeoPlayerID: "spVideoPlayer",
//         events: {
//             onFirstPlay: function () {
//                 //liveRamp.track("play_supportpath");
//             },
//             onPlay: function () {
//                 //liveRamp.track("play_supportpath");
//             }
//         }
//     };
//     return {
//         init: function () {
//             contentVideoPlayer.init(args);
//         },
//         initModal: function () {
//             args.embed = false;
//             contentVideoPlayer.init(args);
//         },
//         show: function () {
//             contentVideoPlayer.show();
//         },
//         prevSlide: function () {
//             contentVideoPlayer.prevSlide();
//         },
//         showSlide: function (num) {
//             contentVideoPlayer.showSlide(num);
//         },
//         nextSlide: function () {
//             contentVideoPlayer.nextSlide();
//         }
//     }
// })();

var tabs={

    init: function(element){

    },
    open_tab_content:function(){

    }
};
$('a.tab-button').on('click',function(){
    var _this = $(this),
        _thisID = _this.attr('id')
    data_tab = _this.attr('data-tab');

    var tabButton1 = $('a#tab-button1'), tabButton2 = $('a#tab-button2'), tabButton3 = $('a#tab-button3'),
        tab1 = $('div#tab1'), tab2 = $('div#tab2'), tab3 = $('div#tab3');
    var tabTop1 = $('div#tab-top1'), tabTop2 = $('div#tab-top2'), tabTop3 = $('div#tab-top3');
    switch(_thisID){
        case 'tab-button1':
            tabButton1.addClass('active').removeClass('inactive');
            tab1.addClass('active').removeClass('inactive');
            tabButton2.addClass('inactive').removeClass('active');
            tab2.addClass('inactive').removeClass('active');
            tabButton3.addClass('inactive').removeClass('active');
            tab3.addClass('inactive').removeClass('active');
            tabTop1.addClass('active');
            tabTop2.removeClass('active');
            tabTop3.removeClass('active');
            break;
        case 'tab-button2':
            tabButton2.addClass('active').removeClass('inactive');
            tab2.addClass('active').removeClass('inactive');
            tabButton1.addClass('inactive').removeClass('active');
            tab1.addClass('inactive').removeClass('active');
            tabButton3.addClass('inactive').removeClass('active');
            tab3.addClass('inactive').removeClass('active');
            tabTop1.removeClass('active');
            tabTop2.addClass('active');
            tabTop3.removeClass('active');
            break;
        case 'tab-button3':
            tabButton3.addClass('active').removeClass('inactive');
            tab3.addClass('active').removeClass('inactive');
            tabButton1.addClass('inactive').removeClass('active');
            tab1.addClass('inactive').removeClass('active');
            tabButton2.addClass('inactive').removeClass('active');
            tab2.addClass('inactive').removeClass('active');
            tabTop1.removeClass('active');
            tabTop2.removeClass('active');
            tabTop3.addClass('active');
            break;
    }

});



if (typeof (Date.toStringFormat) == "undefined") {
    Date.prototype.toStringFormat = function (formatString, makeUTC) {
        function addLeadingZero(val) {
            return val < 10 ? "0" + val.toString() : val.toString();
        }
        if (formatString == undefined || formatString == null || formatString.trim() == "") {
            return this.toString();
        } else {
            var rtnVal = formatString;
            var utc = typeof(makeUTC) == "boolean" ? makeUTC : false;
            if (formatString.indexOf("yyyy") != -1) {
                rtnVal = rtnVal.replace("yyyy", utc ? this.getFullYear() : this.getFullYear());
            }
            if (formatString.indexOf("MM") != -1) {
                rtnVal = rtnVal.replace("MM", addLeadingZero((utc ? this.getUTCMonth() : this.getMonth()) + 1));
            }
            if (formatString.indexOf("dd") != -1) {
                rtnVal = rtnVal.replace("dd", addLeadingZero(utc ? this.getUTCDate() : this.getDate()));
            }
            if (formatString.indexOf("HH") != -1) {
                rtnVal = rtnVal.replace("HH", addLeadingZero(utc ? this.getUTCHours() : this.getHours()));
            }
            if (formatString.indexOf("mm") != -1) {
                rtnVal = rtnVal.replace("mm", addLeadingZero(utc ? this.getUTCMinutes() : this.getMinutes()));
            }
            if (formatString.indexOf("ss") != -1) {
                rtnVal = rtnVal.replace("ss", addLeadingZero(utc ? this.getUTCSeconds() : this.getSeconds()));
            }
            return rtnVal;
        }
    }
}
if (typeof (Date.toUTCStringFormat) == "undefined") {
    Date.prototype.toUTCStringFormat = function (formatString) {
        return this.toStringFormat(formatString, true);
    }
}
//var siteAnalytics = (function () {
(function () {
    var $window = $(window);
    var $footer = $("footer");
    var $isi = $("#isi");
    function trackScrollBottom(category, action, label) {
        $window.scroll(function () {
            var scrollTracked = $window.data("scrollTrackedBottom");
            if (scrollTracked !== true) {
                var winHeight = parseInt($window.height(), 10);
                var scrollTop = parseInt($window.scrollTop(), 10);
                if ($isi.length == 0 || $isi.css("display") == "none") {
                    var footerTop = parseInt($footer.offset().top, 10);
                    if (winHeight + scrollTop >= footerTop) {
                        evoAnalytics.trackOnLoad([evoAnalytics.category, "Scroll", "Scroll down to view bottom of page"]);
                        $window.data("scrollTrackedBottom", true);
                    }
                } else {
                    var isiTop = parseInt($isi.offset().top, 10);
                    if (winHeight + scrollTop >= isiTop) {
                        evoAnalytics.trackOnLoad([evoAnalytics.category, "Scroll", "Scroll down to view bottom of page"]);
                        $window.data("scrollTrackedBottom", true);
                    }
                }
            }
        });
    }
    function trackModules() {
        var $sections = [],
            $sectionsToUse;

        if ($('body').hasClass('harvoni-wall')) {
          $sectionsToUse = $('main > .foreground > section');
        }
        else {
          $sectionsToUse = $('main > section');
        }

        $sectionsToUse.each(function () {
            $sections.push($(this));
        });

        if ($sections.length > 1) {
          $window.scroll(function () {
            var winHeight = parseInt($window.height(), 10);
            var scrollTop = parseInt($window.scrollTop(), 10);
            var modulesY = [];
            var $patientDir = $('#wall-two .patient-directory');

            for (var i = 1; i < $sections.length; i++) {
              if ($sections[i].data("scrollTracked") == undefined && (winHeight + scrollTop >= parseInt($sections[i].offset().top, 10) + Math.floor(parseInt($sections[i].height(), 10) / 3))) {
                  evoAnalytics.trackOnLoad([evoAnalytics.category, "Scroll", ("Module " + (i+1))]);
                  $sections[i].data("scrollTracked", true);
                  break;
              }
            }

            // for "Choose A Story Below" on RPS
            if ($patientDir.length > 0 && $patientDir.data("scrollTracked") == undefined && (winHeight + scrollTop >= parseInt($patientDir.offset().top, 10) + Math.floor(parseInt($patientDir.height(), 10) / 3))) {
                evoAnalytics.trackEvent($patientDir.data('analytics-scrollintoview'));
                $patientDir.data("scrollTracked", true);
            }

          });
        }
    }
    $(document).ready(function () {
        trackModules();
        trackScrollBottom();
    });
//    return {
//        trackScrollBottom: function () {
//            var category = evoAnalytics.category;
//            var action = null;
//            var label = null;
//            if (arguments.length >= 1) {
//                if (arguments.length >= 2) {
//                    if (arguments.length >= 3) {
//                        category = arguments[0];
//                        action = arguments[1];
//                        label = arguments[2];
//                    } else {
//                        action = arguments[0];
//                        label = arguments[1];
//                    }
//                } else {
//                    action = arguments[0];
//                }
//            }
//            trackScrollBottom(category, action, label);
//        }
//    }
})();
var liveRamp = (function () {
    /*var events = {
        dr_discussion_guide_download: "keyAct1",
        find_a_doctor: "keyAct2",
        rm_opt_in: "keyAct3",
        download_coupon: "keyAct4",
        play_commercial: "keyAct5",
        play_supportpath: "keyAct6",
        play_carol: "keyAct7"
    }
    var defaultData = {};
    var serverLoadDate = new Date();
    var clientLoadDate = new Date();
    function getCurrentTimestamp() {
        return new Date(new Date().getTime() - clientLoadDate.getTime() + serverLoadDate.getTime()).toUTCStringFormat("yyyyMMddHHmmss");
    }
    function serializeData(data) {
        var arr = [];
        for (var key in data) {
            switch (key) {
                case "event":
                    arr.push(data[key] + "=1");
                    break;
                default:
                    arr.push(key + "=" + data[key]);
                    break;
            }
        }
        return encodeURIComponent(arr.join(","));
    }
    function track(args) {
        var data = defaultData;
        if (args != undefined) {
            $.extend(data, args);
        }
        document.getElementById("ifLiveRamp").src = (location.protocol + "//di.rlcdn.com/396996.html?pdata=" + serializeData(data));
    }
    $(document).ready(function () {
        $("*[data-liveramp-event]").each(function () {
            var $this = $(this);
            var keyVal = $this.data("liveramp-event");
            if (keyVal != undefined) {
                var key = null;
                var val = null;
                var args = {};
                if (keyVal.indexOf("=") != -1) {
                    key = keyVal.substring(0, keyVal.indexOf("="));
                    val = keyVal.substring(keyVal.indexOf("=") + 1);
                    args[key] = val;
                } else {
                    args.event = keyVal;
                }
                $this.click(function () {
                    liveRamp.track(args);
                });
            }
        });
    });*/
    return {
        init: function (args) {
            /*if (args != undefined) {
                if (typeof (args.data) != "undefined") {
                    defaultData = args.data;
                }
                if (typeof (args.serverDate) != "undefined") {
                    serverLoadDate = args.serverDate;
                }
            }*/
        },
        track: function (args) {
            /*var opts = {
                timestamp: getCurrentTimestamp()
            }
            if (args != undefined) {
                if (typeof (args) == "string") {
                    var key = null;
                    var val = null;
                    if (args.indexOf("=") != -1) {
                        key = args.substring(0, args.indexOf("="));
                        val = args.substring(args.indexOf("=") + 1);
                        opts[key] = val;
                    } else {
                        var nonAlphaNumeric = /[^a-z0-9]/gi;
                        var e = events[args.toLowerCase().replace(nonAlphaNumeric, "_")];
                        if (e != undefined) {
                            opts.event = e;
                        } else {
                            opts.event = args;
                        }
                    }
                } else {
                    if (typeof (args.event) != "undefined") {
                        var nonAlphaNumeric = /[^a-z0-9]/gi;
                        var e = events[args.event.toLowerCase().replace(nonAlphaNumeric, "_")];
                        if (e != undefined) {
                            opts.event = e;
                        } else {
                            opts.event = args.event;
                        }
                    } else {
                        $.extend(opts, args);
                    }
                }
            }
            track(opts);
            */
        }
    };
})();

// $(document).ready(function() {
//   if($('body').hasClass('harvoni-wall'))
//     wall.init();
// })

/*** CONTENT TABS ***/
$(document).ready(function () {
    $(".tab-container").each(function () {
        var $container = $(this);
        var $tabs = $container.find(".tabs");
        var $contents = $container.find(".tab-contents");
        $tabs.find(".tab").each(function (i) {
            $(this).find("a").each(function () {
                $(this).click(function (e) {
                    e.preventDefault();
                    $tabs.find(".active").removeClass("active");
                    $tabs.find(".tab" + (i + 1)).addClass("active");
                    $contents.find(".active").removeClass("active");
                    $contents.find(".tab" + (i + 1)).addClass("active");
                });
            });
        });
    });
});